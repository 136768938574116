<template>
  <div class="d-flex flex-column">
<!--    &lt;!&ndash; Torna al processo &ndash;&gt;-->
<!--     <router-link-->
<!--      :to="{-->
<!--      name: 'processDetail',-->
<!--      params: { id: this.id }-->
<!--      }"-->
<!--      v-slot="{ href, navigate }">-->
<!--      <a :href="href" class="mb-5 btn btn-icon bg-warning mx-auto btn-lg px-2"-->
<!--      @click="navigate" id="pop-back">-->
<!--        <span class="symbol symbol-rounded">-->
<!--           <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">-->
<!--           <i class="fas fa-home text-white"></i></span>-->
<!--        </span>-->
<!--      </a>-->
<!--    </router-link>-->
<!--    <b-popover target="pop-back" triggers="hover focus">-->
<!--      <template v-slot:title>{{$t("PROCESSMENU.BACK")}}</template>-->
<!--    </b-popover>-->

    <!-- Le fasi -->
  <!--
    <router-link
      :to="{
      name: 'processAbout',
      params: { process_id: this.id }
      }"
      v-slot="{ href, navigate }">
      <a :href="href" class="mb-5 btn btn-icon bg-warning mx-auto btn-lg px-2"
      @click="navigate" id="pop-about">
        <span class="symbol symbol-rounded">
          <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">
            <span class="svg-icon svg-icon-white svg-icon-2x">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
                    <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1"/>
                    <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1"/>
                </g>
              </svg>
            </span>
            </span>
        </span>
      </a>
    </router-link>
    <b-popover target="pop-about" triggers="hover focus">
      <template v-slot:title>{{$t("PROCESSMENU.POP1")}}</template>
       <p>{{$t("PROCESSMENU.POP1DESCRIPTION")}} </p>
    </b-popover>
    -->

    <!-- About -->
    <router-link
      :to="{
        name: 'processAbout',
        params: { process_id: this.id }
      }"
      v-slot="{ href, navigate }"
      v-if="is_process_about"
    >
      <a :href="href" class="mb-5 btn btn-icon bg-warning mx-auto btn-lg px-2"
         @click="navigate" id="pop-about">
        <span class="symbol symbol-rounded">
          <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">
            <i class="fas fa-list text-white icon-2x pb-2"></i>
          </span>
        </span>
      </a>
    </router-link>
    <b-popover target="pop-about" triggers="hover focus">
      <template v-slot:title>{{$t("PROCESSMENU.ABOUT")}}</template>
    </b-popover>


  <!-- Istruzioni -->
  <router-link
      :to="{
      name: 'processGuidelines',
      params: { process_id: this.id }
      }"
      v-slot="{ href, navigate }"
      v-if="is_process_guidelines"
  >
    <a :href="href" class="mb-5 btn btn-icon bg-warning mx-auto btn-lg px-2"
       @click="navigate" id="pop-guidelines">
        <span class="symbol symbol-rounded">
          <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">
          <span class="svg-icon svg-icon-white svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect x="0" y="0" width="24" height="24"/>
              <path d="M13.6855025,18.7082217 C15.9113859,17.8189707 18.682885,17.2495635 22,17 C22,16.9325178 22,13.1012863 22,5.50630526 L21.9999762,5.50630526 C21.9999762,5.23017604 21.7761292,5.00632908 21.5,5.00632908 C21.4957817,5.00632908 21.4915635,5.00638247 21.4873465,5.00648922 C18.658231,5.07811173 15.8291155,5.74261533 13,7 C13,7.04449645 13,10.79246 13,18.2438906 L12.9999854,18.2438906 C12.9999854,18.520041 13.2238496,18.7439052 13.5,18.7439052 C13.5635398,18.7439052 13.6264972,18.7317946 13.6855025,18.7082217 Z" fill="#000000"/>
              <path d="M10.3144829,18.7082217 C8.08859955,17.8189707 5.31710038,17.2495635 1.99998542,17 C1.99998542,16.9325178 1.99998542,13.1012863 1.99998542,5.50630526 L2.00000925,5.50630526 C2.00000925,5.23017604 2.22385621,5.00632908 2.49998542,5.00632908 C2.50420375,5.00632908 2.5084219,5.00638247 2.51263888,5.00648922 C5.34175439,5.07811173 8.17086991,5.74261533 10.9999854,7 C10.9999854,7.04449645 10.9999854,10.79246 10.9999854,18.2438906 L11,18.2438906 C11,18.520041 10.7761358,18.7439052 10.4999854,18.7439052 C10.4364457,18.7439052 10.3734882,18.7317946 10.3144829,18.7082217 Z" fill="#000000" opacity="0.3"/>
          </g>
      </svg></span></span>
        </span>
    </a>
  </router-link>
  <b-popover target="pop-guidelines" triggers="hover focus">
    <template v-slot:title>{{$t("PROCESSMENU.ISTRUZIONI")}}</template>
  </b-popover>

    <!-- Narrazione -->
    <router-link
        :to="{
      name: 'processNarration',
      params: { process_id: this.id }
      }"
        v-slot="{ href, navigate }"
        v-if="is_process_narration"
    >
      <a :href="href" class="mb-5 btn btn-icon bg-warning mx-auto btn-lg px-2"
         @click="navigate" id="pop-narrazione">
        <span class="symbol symbol-rounded">
          <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">
          <span class="svg-icon svg-icon-white svg-icon-2x">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
                    <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1"/>
                    <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1"/>
                </g>
              </svg>
          </span></span>
        </span>
      </a>
    </router-link>
    <b-popover target="pop-narrazione" triggers="hover focus">
      <template v-slot:title>{{$t("PROCESSMENU.NARRAZIONE")}}</template>
    </b-popover>

    <!-- FAQ -->
    <router-link
      :to="{
        name: 'processFaq',
        params: { process_id: this.id }
      }"
      v-slot="{href,navigate}"
      v-if="is_process_faq"
    >
      <a
        :href="href" class="mb-5 btn btn-icon bg-warning mx-auto btn-lg px-2"
        @click="navigate" id="pop-faq"
      >
        <span class="symbol symbol-rounded">
          <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">
            <i class="fas fa-question text-white icon-2x pb-2"></i>
          </span>
        </span>
      </a>
    </router-link>
    <b-popover target="pop-faq" triggers="hover">
      <template v-slot:title>{{$t("PROCESSMENU.FAQ")}}</template>
    </b-popover>

    <!-- Challenges -->
    <!-- <router-link
    :to="{
      name: 'processListChallenges',
      params: { process_id: process_id }
      }"
      v-slot="{ href, navigate }">
      <a :href="href" class="mb-5 btn btn-icon bg-primary mx-auto d-flex btn-lg px-2"
      @click="navigate" id="pop-challenge"
      >
        <span class="symbol symbol-rounded">
           <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">
           <span class="svg-icon svg-icon-white svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24"/>
                <path d="M3.5,3 L5,3 L5,19.5 C5,20.3284271 4.32842712,21 3.5,21 L3.5,21 C2.67157288,21 2,20.3284271 2,19.5 L2,4.5 C2,3.67157288 2.67157288,3 3.5,3 Z" fill="#000000"/>
                <path d="M6.99987583,2.99995344 L19.754647,2.99999303 C20.3069317,2.99999474 20.7546456,3.44771138 20.7546439,3.99999613 C20.7546431,4.24703684 20.6631995,4.48533385 20.497938,4.66895776 L17.5,8 L20.4979317,11.3310353 C20.8673908,11.7415453 20.8341123,12.3738351 20.4236023,12.7432941 C20.2399776,12.9085564 20.0016794,13 19.7546376,13 L6.99987583,13 L6.99987583,2.99995344 Z" fill="#000000" opacity="0.3"/>
            </g>
        </svg></span></span>
        </span>
      </a>
    </router-link>
    <b-popover target="pop-challenge" triggers="hover focus">
      <template v-slot:title>Challenges del processo</template>
    </b-popover> -->

    <!-- Scenario -->
    <!-- <router-link
      :to="{
      name: 'processListScenarios',
      params: { process_id: process_id }
      }"
       v-slot="{ href, navigate }">
      <a :href="href" class="mb-5 btn btn-icon bg-secondary mx-auto d-flex btn-lg px-2"
      @click="navigate" id="pop-context"
      >
        <span class="symbol symbol-rounded">
           <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">
           <span class="svg-icon svg-icon-white svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24"/>
                <path d="M13,18.9450712 L13,20 L14,20 C15.1045695,20 16,20.8954305 16,22 L8,22 C8,20.8954305 8.8954305,20 10,20 L11,20 L11,18.9448245 C9.02872877,18.7261967 7.20827378,17.866394 5.79372555,16.5182701 L4.73856106,17.6741866 C4.36621808,18.0820826 3.73370941,18.110904 3.32581341,17.7385611 C2.9179174,17.3662181 2.88909597,16.7337094 3.26143894,16.3258134 L5.04940685,14.367122 C5.46150313,13.9156769 6.17860937,13.9363085 6.56406875,14.4106998 C7.88623094,16.037907 9.86320756,17 12,17 C15.8659932,17 19,13.8659932 19,10 C19,7.73468744 17.9175842,5.65198725 16.1214335,4.34123851 C15.6753081,4.01567657 15.5775721,3.39010038 15.903134,2.94397499 C16.228696,2.49784959 16.8542722,2.4001136 17.3003976,2.72567554 C19.6071362,4.40902808 21,7.08906798 21,10 C21,14.6325537 17.4999505,18.4476269 13,18.9450712 Z" fill="#000000" fill-rule="nonzero"/>
                <circle fill="#000000" opacity="0.3" cx="12" cy="10" r="6"/>
            </g>
        </svg></span></span>
        </span>
      </a>
    </router-link>
    <b-popover target="pop-context" triggers="hover focus">
      <template v-slot:title>Mappe e infografiche</template>
    </b-popover> -->

    <!-- Community
    <router-link
      :to="{
      name: 'processListGroups',
      params: { process_id: id }
      }"
      v-slot="{ href, navigate }"
      >
      <a :href="href" class="mb-5 btn btn-icon bg-warning mx-auto btn-lg px-2"
      @click="navigate" id="pop-group"
      >
        <span class="symbol symbol-rounded">
           <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">
           <span class="svg-icon svg-icon-white svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <polygon points="0 0 24 0 24 24 0 24"/>
                <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
            </g>
        </svg></span></span>
        </span>
      </a>
    </router-link>
    <b-popover target="pop-group" triggers="hover focus">
      <template v-slot:title>{{$t("PROCESSMENU.COMMUNITY")}}</template>
    </b-popover>-->

    <!-- Assistenza -->
    <a
        class="mb-5 btn btn-icon bg-primary mx-auto btn-lg px-2"
        @click.prevent="alertHelp" id="pop-help"
    >
      <span class="symbol symbol-rounded">
        <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">
          <i class="far fa-life-ring text-white icon-2x pb-2"></i>
        </span>
      </span>
    </a>
    <b-popover target="pop-help" triggers="hover focus">
      <template v-slot:title>{{$t("PROCESSMENU.ASSISTENZA")}}</template>
    </b-popover>

  </div>
</template>

<script>
import { alertMixin } from "@/mixins/alert";

export default {
  name: "process-menu",
  mixins: [alertMixin],
  props: ["process_id", "is_process_about", "is_process_faq", "is_process_narration", "is_process_guidelines"],
  data() {
    return {
      id: this.process_id
    };
  }
};
</script>

<style scoped></style>
